import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledCareersBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
  })
)

export const styledCareers = css`
  ${styledCareersBps};
`

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 416, 497],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 328, 328],
    textAlign: ['left'],
    marginTop: [30, 30, 0, 0]
  })
)

export const PBps = css(
  mq({
    maxWidth: [328, '100%', 328, 328]
  })
)

export const imgBps = css(
  mq({
    maxWidth: [220, 230, 250, 250],
    height: [213, 223, 244, 244],
    marginLeft: [0, 0, 144, 246]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  h5 {
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PHeaderBps};
  }
  span {
    font-size: 15.7px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.53;
    letter-spacing: 0.15px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }
  img {
    ${imgBps};
  }
`

export const ourPillars = css`
  h6 {
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 8px;
  }
  p {
    ${PBps};
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 24.5px;
  }
`