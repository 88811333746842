import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'
import Hr from '../components/Hr'
import Paragraph from '../components/Paragraph'
import H4 from '../components/H4'
import H6 from '../components/H6'
import Body2 from '../components/Body2'
import ImgCarreiras from '../images/carreiras/carreiras.png'

import { Container } from '../components/styles/Layout.styles'
import { 
  styledCareers,
  header,
  ourPillars
} from '../components/styles/Careers.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledCareers}>
      <Container>
        <Row
          flexDirection={['column', 'column', 'row']}
          css={header}
          mb={42}
        >
          <Column
            width={[1, 1, 1/2, 1/2]}
          >
            <img src={ImgCarreiras} />
          </Column>
          <Column
            width={[1, 1, 1/2, 1/2]}
            style={{
              display: 'flex',
              alignItems:'center'
            }}
          >
            <Paragraph>Somos apaixonados por tecnologia e acreditamos que podemos usá-la para transformar a vida das pessoas. E, para nos ajudar nessa missão, queremos os melhores ao nosso lado. Se você é curioso, inovador e adora tecnologia, queremos conhecer você melhor. Venha fazer parte de uma das melhores empresas para se trabalhar.</Paragraph>
          </Column>
        </Row>
        <Div
          alignItems={'center'}
        >
          <Hr
            lineColor='lightPeriwinkle'
            maxWidth={[328, '100%' , 680, 680]}
            style={{
              opacity: 0.5
            }}
          />
        </Div>
        <Row
          css={header}
          mt={40}
          mb={[40, 70]}
          justifyContent={['left', 'center']}
        >
          <Column>
            <H4>Nossos Pilares</H4>
          </Column>
        </Row>
        <Row
          css={ourPillars}
          justifyContent={['left', 'center']}
          flexDirection={['column', 'column', 'row', 'row']}
          mb={[0, 0, 24, 24]}
        >
          <Div
            maxWidth={['100%', '100%', 328, 328]}
          >
            <Column>
              <H6>Integridade</H6>
              <Body2>Assim como prezamos pela integridade de informações, construímos nossas relações comerciais e interpessoais baseados na transparência, confiança e ética.</Body2>
              <Hr
                lineColor='lightPeriwinkle'
                maxWidth={[50]}
                style={{
                  opacity: 0.5,
                  margin: 0
                }}
              />
            </Column>
          </Div>
          <Div
            maxWidth={['100%', '100%', 328, 328]}
          >
            <Column
              mt={[24, 24, 0, 0]}
            >
              <H6>Parceria</H6>
              <Body2>Temos o compromisso de compreender as necessidades de quem está ao nosso lado com empatia e encontrar as soluções mais efetivas em qualquer situação.</Body2>
              <Hr
                lineColor='lightPeriwinkle'
                maxWidth={[50]}
                style={{
                  opacity: 0.5,
                  margin: 0
                }}
              />
            </Column>
          </Div>
        </Row>
        <Row
          css={ourPillars}
          justifyContent={['left', 'center']}
          flexDirection={['column', 'column', 'row', 'row']}
        >
          <Div
            maxWidth={['100%', '100%', 328, 328]}
          >
            <Column
              mt={[24, 24, 0, 0]}
            >
              <H6>Disponibilidade</H6>
              <Body2>Seus dados estão sempre disponíveis, assim como nosso suporte. Estamos a postos para auxiliar nossos clientes a todo o momento, sempre que for preciso.</Body2>
              <Hr
                lineColor='lightPeriwinkle'
                maxWidth={[50]}
                style={{
                  opacity: 0.5,
                  margin: 0
                }}
              />
            </Column>
          </Div>
          <Div
            maxWidth={['100%', '100%', 328, 328]}
          >
            <Column
              mt={[24, 24, 0, 0]}
            >
              <H6>Inovação</H6>
              <Body2>Somos movidos pelo desafio de encontrar o novo. Encaramos todas as eventualidades como boas oportunidades de desenvolver soluções inteligentes e ágeis.</Body2>
              <Hr
                lineColor='lightPeriwinkle'
                maxWidth={[50]}
                style={{
                  opacity: 0.5,
                  margin: 0
                }}
              />
            </Column>
          </Div>
        </Row>
        <Row
          pt={[50, 70]}
          pb={[50, 70]}
        >
          <Div
            style={{
              fontSize: 14,
              lineHeight: 1.71,
              color: 'rgba(0, 0, 0, 0.6)',
              marginBottom: 16,
              textAlign: 'center',
              fontWeight: 'bold'
            }}
          >
            Nenhuma vaga disponível no momento
          </Div>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Carreiras' />
      <PageTemplate
        title='Carreiras'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
