import React from 'react'
import styled from '@emotion/styled'

import Atom from './styles/Atom.styles'
import { mq } from './styles/Layout.styles'

const StyledHr = styled(Atom)(props => (
  mq({
    border: 'none',
    borderBottom: `1px solid ${props.theme.ds.brand(props.lineColor)}`,
    width: '100%'
  })
))

const Hr = (
  props
) => {
  return (
    <StyledHr
      as='hr'
      {...props}
    />
  )
}

export default Hr